@use "../../common/breakpoints" as *;
@use "../../common/mixin" as *;
@use "../../common/color" as *;

.randomplayer{
    .inner_Screen{
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin: 50px auto;
    }

    .user_profile{
        text-align: center;
        position: relative;

        img{
            width: 100px;
            height: 100px;
            object-fit: contain;
            border-radius: 50px;

        }
    }

    .opponent_image{
        text-align: center;

    }
}