// themecolor
$themecolor: #ef5488;

$primarybtn:#ef5488;

$white:#fff;

$mate-black:#212121;

$black:#474646;

$gradient-one:#b57f35;

$gradient-two:#ef5488;

$gradient-three:#6c245a;
